import styled from 'styled-components';

const rem = px => `${(px / 16) * 1}rem`;

export const StyledText = styled('div')`
  text-align: ${({ align }) => align || 'left'};
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
  color: ${({ theme }) => theme.colors.black};
  strong {
    font-weight: 600;
    line-height: inherit;
    font-size: inherit;
  }
  p {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    margin-top: 0;
    margin-bottom: 0;
  }
  h2 {
    font-size: ${rem(30)};
    margin-bottom: 1.4rem;
  }

  h3 {
    font-size: ${rem(26)};
    margin-bottom: 1.4rem;
  }

  h4 {
    font-size: ${rem(23)};
    margin-bottom: 1.4rem;
  }
  a {
    color: ${({ theme }) => theme.colors.orange};
    transition: color 0.3s ease-in-out;
    text-decoration: underline;
    &:hover {
      color: ${({ theme }) => theme.colors.lightOrange};
      text-decoration: underline;
    }
  }
  ul,
  ol {
    margin: 2rem 0 2rem 0;

    li {
      margin-top: 10px;
      color: ${({ theme }) => theme.colors.black};
    }

    ul,
    ol {
      margin-top: 0;
    }
  }

  ul {
    margin-left: 0;

    li {
      list-style: none outside none;
      padding-left: ${rem(20)};
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: ${rem(5)};
        left: 0;
        width: ${rem(14)};
        height: ${rem(14)};
        display: inline-block;
        border: solid #fff ${rem(2)};
        border-radius: 50%;
        background-color: ${({ theme }) => theme.colors.orange};
      }
    }

    ul {
      li {
        list-style: disc;
      }
    }
  }

  ol {
    padding-left: 2rem;
    li {
      list-style: decimal;
    }
  }
  blockquote {
    margin: 30px 0;
    font-size: ${rem(23)};
    line-height: 1.5em;
    color: #505050;
    text-align: center;
    font-style: italic;
  }
  .table {
    overflow-x: auto;
    margin: 30px 0;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ddd;
  }

  table.oc-alternate-rows tbody tr:nth-child(2n) {
    background: #f5f5f5;
  }

  th {
    font-weight: 600;
    background-color: ${({ theme }) => theme.colors.lightOrange};
  }

  th,
  td {
    padding: ${rem(15)};
    border: 1px solid #dadada;
    text-align: left;
  }
  figure {
    margin: 0;

    img {
      margin: 0;
    }
  }
`;
